import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'scale_up';
const namespace = 'ui-pdp-icon ui-pdp-icon--scale-up';

const IconScaleUp = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconScaleUp.propTypes = {
  className: string,
};

IconScaleUp.defaultProps = {
  className: null,
};

IconScaleUp.ICON_ID = ICON_ID;

export default React.memo(IconScaleUp);
export { IconScaleUp };
